


@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap');

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

body {
  margin: 0px;
  background-color: #fff; 
  display: flex;
}

.panorama {
  width: 80%;
  height: 100%;
  margin: auto;
  border-radius: 20px;
}

#iframePannellum {
  width: 100%;
  height: 100%;
  margin: auto;
}

.top-div {
  padding: 0px;
  height: 25vh;
}

.pnlm-ui {
  width: 300px;
}


h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Cardo, sans-serif;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  text-align: center;
  color: #333333;
  margin-left: 2%;
  margin-right: 2%;
}

.direct-navigation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.direct-navigation button {
  flex: 1;
}

button {
  height: 8vh;
  width: 15vw;
  margin-right: 1vw;
  margin-left: 1vw;
  padding-right: 2%;
  padding-left: 2%;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgb(126 149 245 / 64%);
  text-transform: capitalize;
  font-family: Raleway, sans-serif;
  color: #0932da;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  border-width: 0px;
  cursor: pointer;
}

button:hover {
  background-color: #c0cdff;
  box-shadow: 0 0 0px 0px #fff;
}

button:disabled {
  background-color: #EDEDED;
  color: #35468B;
  font-weight: 500;
  box-shadow: 0 0 0px 0px #fff;
  cursor: default;
}

.viewer {
  height: 65vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.incremental-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  padding-top: 1%;
  padding-bottom: 1%;
}

.decrement {
  display: flex;
  width: 25vw;
  height: 7vh;
  margin-right: 1%;
  margin-left: 1%;
  padding-top: 0%;
  padding-bottom: 0%;
}

.increment {
  display: flex;
  width: 25vw;
  height: 7vh;
  margin-right: 1%;
  margin-left: 1%;
  padding-top: 0%;
  padding-bottom: 0%;
}

/* tablet */ 
@media (max-width: 991px) {
  .top-div {

  }
  
  h2 {

  }

  button {

  }

  .viewer {

  }

  .incremental-navigation {

  }

  .decrement {

  }

  .increment {

  }
}

/* mobile landscape */ 
@media (max-width: 767px) {
  /* .title {
   margin-bottom: 25px;
  } */
  .top-div {
    
  }
  
  h2 {

  }

  button {

  }

  .viewer {

  }

  .incremental-navigation {

  }

  .decrement {

  }

  .increment {

  }

  #panorama {
    

  }
}
/* mobile portrait */ 
@media (max-width: 476px) {
  
  .top-div {
    height: 20vh;
    padding-top: 1%;
    padding-bottom: 5%;
  }

  #iframePannellum {
    width: 100%;
    height: 100%;
  }

  h2 {
    font-size: 32px;
    line-height: 38px;
  }

  button {
    font-size: 14px;
    line-height: 18px;
    width: 30vw;
    height: 8vh;
  }
  
  .panorama {
    width: 100%;
  }
  .viewer {
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .incremental-navigation {
    height: 10vh;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .decrement {
    width: 45%;
    height: 7vh;
  }

  .increment {
    width: 45%;
    height: 7vh;
  }
}




